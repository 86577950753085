import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/shell/seo"
import Layout from "../components/shell/layout"
import Security from "../components/help/pageContents/Security"

const SecurityPage = ({ data }) => (
  <Layout>
    <SEO title="Security" />
    <Security data={data.securityJson} />
  </Layout>
)

export default SecurityPage

export const query = graphql`
  {
    securityJson {
      InformationSecurity {
        title
        contents {
          description
          sub_title
        }
      }
    }
  }
`

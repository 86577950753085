import { Container, makeStyles, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0px 0 496px 0",
    transition: "all .3s ease-in-out",
    "@media screen and (min-width: 410px)": {
      padding: "0px 0 477px 0",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "24px 24px 230px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "40px 24px 233px",
      width: "85%",
      margin: "0 auto",
    },
  },
  paper: {
    padding: "5% 7%",
    backgroundColor: theme.palette.background.paper,
    marginBottom: "40px",
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0",
    },
  },
  title: {
    padding: "20px 0 10px",
    color: "#005CB2",
    fontSize: "20px",
    "@media screen and (min-width: 768px)": {
      fontSize: "25px",
      padding: "0 0 10px",
    },
    "@media screen and (min-width: 1024px)": {
      fontSize: "30px",
    },
  },
  sub_title: {
    fontWeight: "bold",
    margin: "0.5rem 0",
    "@media screen and (min-width: 1024px)": {
      margin: "1rem 0",
    },
  },
  description: {
    margin: "0.5rem 0",
  },
}));

export default function InformationSecurity({ data }) {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Paper className={classes.paper}>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          color="primary"
          className={classes.title}
        >
          {data.title}
        </Typography>
        {data.contents.map((item, index) => (
          <div>
            <Typography
              key={index}
              variant="body1"
              align="justify"
              className={classes.sub_title}
            >
              {item.sub_title}
            </Typography>
            <Typography
              key={index}
              variant="body1"
              align="justify"
              className={classes.description}
            >
              {item.description}
            </Typography>
          </div>
        ))}
      </Paper>
    </Container>
  );
}

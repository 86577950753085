import React from "react"
import InformationSecurity from "../sections/InformationSecurity";

// function ScrollToTop() {
//   if (!window) return;
//   window.scrollTo(0, 0);
// }

export default function Security({data}){
  // ScrollToTop();
    return(
        <section>
            <InformationSecurity data={data.InformationSecurity}/>
        </section>
    )
}